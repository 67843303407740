const _translations = {
    "uk": {
        "header": {
            "Про компанію": "Про компанію",
            "Медіа": "Медіа",
            "Послуги": "Послуги",
            "Вакансії": "Вакансії",
            "Новини": "Новини",
            "Контакти": "Контакти",
        },
        "footer": {
            "Забезпечуємо безпечне виробництво та застосування вибухових матеріалів у різних індустріях.":
                "Забезпечуємо безпечне виробництво та застосування вибухових матеріалів у різних індустріях.",
            "Про компанію": "Про компанію",
            "Продукція": "Продукція",
            "Медіа": "Медіа",
            "Вакансії": "Вакансії",
            "Новини": "Новини",
            "Контакти": "Контакти",
            "Меню:": "Меню:",
            "Графік роботи:": "Графік роботи:",
            "Пн - Пт 8:00 - 17:00": "Пн - Пт 8:00 - 17:00",
            "Сб 8:00 - 13:00": "Сб 8:00 - 13:00",
            "11634, Україна, Житомирська обл., Коростенський р-н, селище міського типу Гранітне(з), вул.Шевченка, будинок":
                "11634, Україна, Житомирська обл., Коростенський р-н, селище міського типу Гранітне(з), вул.Шевченка, будинок"
        },
        "main": {
            // Facial section
            "Виробництво вибухових речовин": "Виробництво вибухових речовин",
            "Послуги": "Послуги",
            "Звітність, інновації та безпека є основними цінностями нашої компанії, що спеціалізується на виробництві вибухових речовин. Ми приводимо в життя найсміливіші ідеї у сфері хімічної безпеки, надаючи надійні рішення для наших клієнтів по всьому світу":
                "Звітність, інновації та безпека є основними цінностями нашої компанії, що спеціалізується на виробництві вибухових речовин. Ми приводимо в життя найсміливіші ідеї у сфері хімічної безпеки, надаючи надійні рішення для наших клієнтів по всьому світу",
            // Why choose us
            "Чому обирають нас": "Чому обирають нас",
            "Сучасне обладнання": "Сучасне обладнання",
            "Використання новітніх бурових станків забезпечує точність та ефективність робіт.": "Використання новітніх бурових станків забезпечує точність та ефективність робіт.",
            "Безпека та своєчасність": "Безпека та своєчасність",
            "Спеціалізований транспорт та професійний підхід гарантують безпечну та своєчасну доставку компонентів та засобів ініціювання.":
                "Спеціалізований транспорт та професійний підхід гарантують безпечну та своєчасну доставку компонентів та засобів ініціювання.",
            "Професійна команда": "Професійна команда",
            "Наші фахівці мають великий досвід у проведенні буровибухових робіт та виробництві вибухових речовин":
                "Наші фахівці мають великий досвід у проведенні буровибухових робіт та виробництві вибухових речовин",
            // About us
            "Про нас": "Про нас",
            "Детальніше": "Детальніше",
            // Services
            "Варто відзначити, що підприємство, маючи родовище граніту рожевого кольору та сучасне обладнання для збагачення відсіву, постачає свою унікальну продукцію ще й виробникам вогнетривких кахлів, облицювальних елементів для камінів та вентильованих фасадів":
                "Варто відзначити, що підприємство, маючи родовище граніту рожевого кольору та сучасне обладнання для збагачення відсіву, постачає свою унікальну продукцію ще й виробникам вогнетривких кахлів, облицювальних елементів для камінів та вентильованих фасадів",
            "Замовити послугу": "Замовити послугу",
            "Замовити": "Замовити",
            // Certificates
            "Сертифікати": "Сертифікати",
            // Media
            "Медіа": "Медіа",
            // Reviews
            "Відгуки": "Відгуки",
            "Залишити відгук": "Залишити відгук",
            "Автор": "Автор",
            "Напишіть ім’я": "Напишіть ім’я",
            "Напишіть": "Напишіть",
            "Посада": "Посада",
            "Напишіть вашу посаду": "Напишіть вашу посаду",
            "Контент": "Контент",
            "Напишіть текст": "Напишіть текст",
            "Відправити": "Відправити",
            "Повідомлення": "Повідомлення",
            "Замовити тендер": "Замовити тендер",
            // Contact us
            "Замовити консультацію": "Замовити консультацію",
            "Залиште свої контакти та отримайте безкоштовний виїзд та консультацію від фахівця":
                "Залиште свої контакти та отримайте безкоштовний виїзд та консультацію від фахівця",
            "Телефон": "Телефон",
            "Напишіть телефон": "Напишіть телефон",
            // Last News
            "Останні новини": "Останні новини",
            "Показати більше": "Показати більше",
            // About us page
            "Команда": "Команда",
            "Досягнення": "Досягнення",
            "«Юні Сервіс» представляє собою приклад успішного бізнесу, що поєднує інновації, якість, безпеку і соціальну відповідальність, що дозволяє їй не тільки досягати високих результатів, але й забезпечувати стійкий розвиток у глобальному масштабі.":
                "«Юні Сервіс» представляє собою приклад успішного бізнесу, що поєднує інновації, якість, безпеку і соціальну відповідальність, що дозволяє їй не тільки досягати високих результатів, але й забезпечувати стійкий розвиток у глобальному масштабі.",
            // Media page
            "Все": "Все",
            "Фото": "Фото",
            "Відео": "Відео",
            // Vacancies page
            "Вакансії": "Вакансії",
            "Тендери": "Тендери",
            "Запросити до тендеру": "Запросити до тендеру",
            "Обов’язки": "Обов’язки",
            // Vacancy detail page
            "грн": "грн",
            "Зважаючи на велику кількість запитів, ми зв’язуємося з кандидатами, які повністю відповідають вимогам вакансії.":
                "Зважаючи на велику кількість запитів, ми зв’язуємося з кандидатами, які повністю відповідають вимогам вакансії.",
            "Запис на співбесіду": "Запис на співбесіду",
            // News page
            "Новини": "Новини",
            // Contacts page
            "Контакти": "Контакти",
            "Адреса": "Адреса",
            "Графік роботи": "Графік роботи",
            "Соц. мережі": "Соц. мережі",
            // Breadcrumbs
            "Головна": "Головна",
            "Про компанію": "Про компанію",
            // Not found
            "Сторінку не знайдено": "Сторінку не знайдено",
            "На головну": "На головну",
            "Ім’я": "Ім’я"
        }
    },
    "en-us": {
        "header": {
            "Про компанію": "About us",
            "Медіа": "Media",
            "Послуги": "Services",
            "Вакансії": "Vacancies",
            "Новини": "News",
            "Контакти": "Contacts",
        },
        "footer": {
            "Забезпечуємо безпечне виробництво та застосування вибухових матеріалів у різних індустріях.":
                "We ensure safe production and use of explosive materials in various industries.",
            "Про компанію": "About us",
            "Продукція": "Services",
            "Медіа": "Media",
            "Вакансії": "Vacancies",
            "Новини": "News",
            "Контакти": "Contacts",
            "Меню:": "Menu:",
            "Графік роботи:": "Schedule:",
            "Пн - Пт 8:00 - 17:00": "Mn - Fr 8:00 - 17:00",
            "Сб 8:00 - 13:00": "St 8:00 - 13:00",
            "11634, Україна, Житомирська обл., Коростенський р-н, селище міського типу Гранітне(з), вул.Шевченка, будинок":
                "11634, Ukraine, Zhytomyr region, Korostenskyi district, urban-type village Granitne(z), Shevchenko street, building"
        },
        "main": {
            // Facial section
            "Виробництво вибухових речовин": "Production of explosives",
            "Послуги": "Services",
            "Звітність, інновації та безпека є основними цінностями нашої компанії, що спеціалізується на виробництві вибухових речовин. Ми приводимо в життя найсміливіші ідеї у сфері хімічної безпеки, надаючи надійні рішення для наших клієнтів по всьому світу":
                "Accountability, innovation and safety are the core values of our explosives company. We bring to life the boldest ideas in the field of chemical safety, providing reliable solutions for our customers around the world",
            // Why choose us
            "Чому обирають нас": "Why choose us",
            "Сучасне обладнання": "Modern equipment",
            "Використання новітніх бурових станків забезпечує точність та ефективність робіт.": "The use of the latest drilling machines ensures the accuracy and efficiency of work.",
            "Безпека та своєчасність": "Security and timeliness",
            "Спеціалізований транспорт та професійний підхід гарантують безпечну та своєчасну доставку компонентів та засобів ініціювання.":
                "Specialized transport and a professional approach guarantee safe and timely delivery of components and means of initiation.",
            "Професійна команда": "Professional team",
            "Наші фахівці мають великий досвід у проведенні буровибухових робіт та виробництві вибухових речовин":
                "Our specialists have extensive experience in drilling and blasting operations and the production of explosives",
            // About us
            "Про нас": "About us",
            "Детальніше": "Details",
            // Services
            "Варто відзначити, що підприємство, маючи родовище граніту рожевого кольору та сучасне обладнання для збагачення відсіву, постачає свою унікальну продукцію ще й виробникам вогнетривких кахлів, облицювальних елементів для камінів та вентильованих фасадів":
                "It is worth noting that the enterprise, having a deposit of pink granite and modern equipment for enrichment of screening, also supplies its unique products to manufacturers of refractory tiles, facing elements for fireplaces and ventilated facades",
            "Замовити послугу": "Order the service",
            "Замовити": "Order",
            // Certificates
            "Сертифікати": "Certificates",
            // Media
            "Медіа": "Media",
            // Reviews
            "Відгуки": "Reviews",
            "Залишити відгук": "Leave a review",
            "Автор": "Author",
            "Напишіть ім’я": "Write the name",
            "Напишіть": "Write the",
            "Посада": "Position",
            "Напишіть вашу посаду": "Write your position",
            "Контент": "Content",
            "Напишіть текст": "Write the text",
            "Відправити": "Send",
            "Повідомлення": "Message",
            "Замовити тендер": "Order a tender",
            // Contact us
            "Замовити консультацію": "Order a consultation",
            "Залиште свої контакти та отримайте безкоштовний виїзд та консультацію від фахівця":
                "Leave your contacts and get a free visit and consultation from a specialist",
            "Телефон": "Phone",
            "Напишіть телефон": "Write your phone number",
            // Last News
            "Останні новини": "Last news",
            "Показати більше": "Show more",
            // About us page
            "Команда": "Team",
            "Досягнення": "Achievements",
            "«Юні Сервіс» представляє собою приклад успішного бізнесу, що поєднує інновації, якість, безпеку і соціальну відповідальність, що дозволяє їй не тільки досягати високих результатів, але й забезпечувати стійкий розвиток у глобальному масштабі.":
                "«Uni Service» is an example of a successful business that combines innovation, quality, safety and social responsibility, which allows it not only to achieve high results, but also to ensure sustainable development on a global scale.",
            //Media page
            "Все": "All",
            "Фото": "Photo",
            "Відео": "Video",
            // Vacancies pages
            "Вакансії": "Vacancies",
            "Тендери": "Tenders",
            "Запросити до тендеру": "Invite to tender",
            "Обов’язки": "Requirements",
            // Vacancy detail page
            "грн": "uah",
            "Зважаючи на велику кількість запитів, ми зв’язуємося з кандидатами, які повністю відповідають вимогам вакансії.":
                "Due to the large number of requests, we contact candidates who fully meet the requirements of the vacancy.",
            "Запис на співбесіду": "Sign up for an interview",
            // News page
            "Новини": "News",
            // Contacts page
            "Контакти": "Contacts",
            "Адреса": "Address",
            "Графік роботи": "Schedule",
            "Соц. мережі": "Social networks",
            // Breadcrumbs
            "Головна": "Home",
            "Про компанію": "About the company",
            // Not found
            "Сторінку не знайдено": "Page not found",
            "На головну": "On the home",
            "Ім’я": "Name"
        }
    }
}

export default function getTranslations(lang, section) {
    return _translations[lang][section]
}